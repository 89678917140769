/* ******************************** */
/* BELOW 1200px */
/* ******************************** */

@media (max-width: 75em) {
    html {
        /* 9px / 16px = 0.5625 = 56.25% */
        font-size: 56.25%;
    }

    /* .hero-text {
        font-size: 7.4rem;
    }

    .hero-sub-text {
        font-size: 2.4rem;
    } */

    /* .hero-img {
        width: 85%;
    } */

    .grid--3-cols {
        grid-template-columns: 1fr 1fr;
    }

    .physio-img:last-child {
        display: none;
    }

    .physio-text-container {
        padding: 8rem 1.6rem;
    }
}

/* ******************************** */
/* BELOW 1136px */
/* ******************************** */

@media (max-width: 71em) {

    .about-us::before,
    .about-us::after {
        width: 45%;
        height: 11%;
        bottom: 47%;
    }
}

/* ******************************** */
/* BELOW 1056px */
/* ******************************** */

@media (max-width: 66em) {

    .about-us::before,
    .about-us::after {
        width: 45%;
        height: 11%;
        bottom: 46%;
    }
}

/* ******************************** */
/* BELOW 1030px */
/* ******************************** */

@media (max-width: 64em) {
    html {
        /* 8px / 16px = 0.5 = 50% */
        font-size: 50%;
    }

    .section-hero {
        padding: 3.2rem 0 7.4rem 0;
    }

    .hero-text {
        font-size: 7.4rem;
    }

    .hero-sub-text {
        font-size: 2.4rem;
    }

    /* .hero-img {
        width: 85%;
    } */

    .heading-tertiary {
        font-size: 3rem;
    }

    .service-subheading {
        font-size: 3.6rem;
        margin-bottom: 6.4rem;
    }

    .physio-subheading {
        font-size: 2.4rem;
    }

    .about-us::before,
    .about-us::after {
        width: 45%;
        height: 12.5%;
        bottom: 46.5%;
    }
}

/* ******************************** */
/* BELOW 931px */
/* ******************************** */

@media (max-width: 58.2em) {

    .about-us::before,
    .about-us::after {
        width: 46%;
        height: 13%;
        bottom: 45%;
    }
}

/* ******************************** */
/* BELOW 816px */
/* ******************************** */

@media (max-width: 51em) {

    .about-us::before,
    .about-us::after {
        width: 48%;
        height: 12%;
        bottom: 45%;
    }

    .about-us-img {
        width: 90%;
    }
}

/* ******************************** */
/* BELOW 776px */
/* ******************************** */

@media (max-width: 48.5em) {
    .section-hero {
        padding: 4.8rem 0 8rem 0;
    }

    .heading-secondary {
        font-size: 4.4rem;
    }

    .subheading {
        font-size: 1.4rem;
    }

    .heading-tertiary {
        margin-bottom: 2.4rem !important;
    }


    .header-hero {
        grid-template-columns: 1fr;
        gap: 8rem;
        text-align: center;
    }

    .hero-text {
        margin-bottom: 3.2rem;
    }

    .hero-img {
        width: 50%;
    }

    .section-services {
        padding: 8rem 0 0;
    }

    .service-text-box {
        padding: 0 0.8rem 0 4.8rem;
    }

    .pilates-list {
        gap: 1.6rem;
    }

    .pilates-list li {
        font-size: 2rem;
        /* font-weight: 500; */
    }

    .service-img-box {
        padding: 3.2rem 4.8rem;
    }

    .service-img-box img {
        width: 95%;
    }

    #left-img {
        width: 140%;
    }

    .left.service-img-box::before {
        right: 8rem;
    }

    .left {
        transform: translateX(8rem);
    }

    .physio-container {
        grid-template-columns: 1fr;
    }

    .physio-text-container {
        grid-row: 1;
        padding: 3.2rem 4.8rem 1.6rem;
        /* padding-top: 0; */
    }

    .grid--3-cols {
        grid-template-columns: repeat(4, 1fr);
    }

    .margin-bottom-big {
        margin-bottom: 4.8rem;
    }

    .service-subheading {
        font-size: 3.6rem;
        margin-bottom: 4.8rem;
    }

    .physio-img-box {
        padding-bottom: 8rem;
        gap: 0.8rem;
    }

    .section-about-us {
        padding: 8rem 3.2rem;
    }

    .about-us {
        margin-bottom: 4.8rem;
    }

    .about-us-img {
        width: 100%;
    }

    .about-us-text {
        font-size: 2.4rem;
    }

    .about-us-icon {
        height: 3.2rem;
        width: 3.2rem;
    }

    .about-us::before,
    .about-us::after {
        width: 48%;
        height: 10%;
        bottom: 38.5%;
    }

    .contact-box {
        grid-template-columns: 1fr 1fr;
        column-gap: 4.8rem;
        row-gap: 4.8rem;
        justify-items: start;
    }

    /* .margin-bottom-med {
        margin-bottom: 3.2rem !important;
    } */

    .contact-icon {
        font-size: 3rem;
        color: #5FB1AD;
        margin-bottom: 0;
    }

    .footer {
        padding: 8rem 3.2rem;
    }

    .grid--footer {
        column-gap: 2.4rem;
    }

    .footer-logo-img {
        width: 80%;
    }

}

/* ******************************** */
/* BELOW 672px */
/* ******************************** */

@media (max-width: 42em) {
    .about-us-content.grid--2-cols {
        grid-template-columns: 1fr;
        gap: 3.2rem;
    }

    .about-us-content-textbox {
        padding: 0 4.8rem;
    }

    .about-us-img {
        width: 60%;
    }

    .about-us::before,
    .about-us::after {
        width: 48%;
        height: 9%;
        bottom: 34%;
    }
}

/* ******************************** */
/* BELOW 576px */
/* ******************************** */

@media (max-width: 36em) {
    .hero-img {
        width: 80%;
    }

    /* MOBILE NAVIGATION */
    .btn-mobile-nav {
        display: block;
    }

    .main-nav {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(100%);
        transition: all 0.5s ease-in;

        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }

    /* .nav-open {
        overflow-y: hidden;
        height: 100vh;
    } */

    .nav-open .main-nav {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        transform: translateX(0);
    }

    .nav-open .icon-mobile-nav[name="menu-outline"] {
        display: none;
    }

    .nav-open .icon-mobile-nav[name="close-outline"] {
        display: block;
    }

    .main-nav-list {
        flex-direction: column;
        gap: 4.8rem;
    }

    .main-nav-links:link,
    .main-nav-links:visited {
        font-size: 3rem;
    }

    /* NORMAL */

    .name {
        font-size: 3.6rem;
    }

    .hero-text {
        font-size: 6.2rem;
    }

    .hero-sub-text {
        font-size: 2rem;
    }

    .main-header {
        padding: 4.8rem;
    }

    .grid--2-cols {
        grid-template-columns: 1fr;
    }

    .grid--2-erows {
        grid-template-rows: auto;
    }

    .mob-grid {
        gap: 4.8rem;
        justify-items: center;
        background-color: #dfefef;
    }

    .dark .mob-grid {
        background-color: #000;
    }

    .mob-grid .heading-tertiary {
        display: flex;
        justify-content: center;
    }

    .service-img-box {
        transform: translateY(4.8rem);
    }

    .service-img-box:nth-child(2) {
        padding-top: 0;
        grid-row: 1;
    }

    .service-img-box:nth-child(6) {
        grid-row: 5;
    }

    .service-img-box img {
        transform: translateX(26%);
    }

    #left-img {
        width: 50%;
        grid-column: span 2;
        transform: translateX(0);
    }

    .service-img-box::before {
        display: none;
    }

    .left.service-img-box::before {
        right: -100px;
        /* width: 150%; */
        display: block;
        content: "";
        /* background-color: #fff; */
        position: absolute;
        width: 200%;
        height: 178%;
        /* top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%); */
        z-index: -1;
    }

    .left {
        justify-items: center;
        transform: translateX(0);
        transform: translateY(4.8rem);
        padding-top: 2.4rem;
    }

    .dark .left::before {
        background-color: #000;
    }

    .service-text-box {
        z-index: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .service-text-box:nth-child(4) {
        padding-bottom: 6.4rem;
    }

    /* .physio {
        background-color: rgb(239, 247, 247, 0);
        background-image: linear-gradient(to bottom, #fff, #dfefef);
    } */

    .physio {
        margin: 0 3.2rem 4.8rem;
    }

    .physio-text-box {
        flex-direction: column;
        gap: 4.8rem;
    }

    .grid--3-cols {
        grid-template-columns: repeat(3, 1fr);
    }

    .physio-img-box {
        padding: 4.8rem 3.2rem 8rem;
    }

    .physio-img:last-child {
        display: block;
    }

    .section-about-us {
        padding: 8rem 2.4rem;
    }

    .about-us-content-textbox {
        padding: 0 3.2rem;
        hyphens: auto;
    }

    .about-us {
        justify-content: space-between;
        gap: 1.6rem;
        padding-top: 1.3rem;
    }

    .about-us-img {
        width: 70%;
        /* justify-self: center; */
    }

    .about-us-icon {
        align-self: flex-start;
        height: 2.4rem;
        width: 2.4rem;
        display: none;
    }

    .about-us-text {
        font-size: 2rem;
        flex-direction: column;
        gap: 1.2rem;
    }

    .dark .about-us-text {
        color: #ababab;
    }

    .about-us::before,
    .about-us::after {
        width: 47%;
        height: 13%;
        bottom: 52.5%;
        display: none;
    }

    .contact-box {
        grid-template-columns: 1fr;
        justify-items: start;
        gap: 4.8rem;
        font-size: 1.8rem;
    }

    .margin-bottom-med {
        margin-bottom: 3.2rem !important;
    }

    .contact,
    .timing,
    .address {
        width: 75%;
        margin: 0 auto;
    }

    .contact {
        font-size: 1.8rem;
    }

    .grid--footer {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 3.2rem;
    }

    .footer-logo-img {
        width: 85%;
    }

    .footer-cons {
        grid-column: span 2;
    }

    .footer-nav {
        grid-column: 2;
        grid-row: 1;
    }

    .footer-heading {
        margin-bottom: 2.4rem;
    }

    .darkmode {
        bottom: 8rem;
    }

    .dark .darkmode {
        box-shadow: 0 .5rem 1rem rgba(255, 255, 255, 0.07);
    }
}

/* - Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98 */

/* - Spacing system (px):
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128 */